"use client"

import type { FormDataType } from "@/modules/featureEnv/types"
import type { FunctionComponent } from "react"

import classNames from "classnames"
import { useEffect, useState } from "react"

import { deleteCookie } from "@/modules/client/cookies/deleteCookie"
import { setCookie } from "@/modules/client/cookies/setCookie"
import { FeatureEnvForm } from "@/modules/featureEnv/Components/FeatureEnvForm"
import {
  FE_COOKIE_DOMAIN,
  FE_COOKIE_NAME_NEXT,
  FE_COOKIE_NAME_RAILS,
  FE_COOKIE_NAME_UI,
  FE_MONOLITH_DURATION_SHORT,
  FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY,
  I18N_DEBUG_COOKIE_NAME,
} from "@/modules/featureEnv/constants"
import {
  getMonolithFeatureEnvInfos,
  getNextFeatureEnvIdentifier,
  getSpaFeatureEnvIdentifier,
} from "@/modules/featureEnv/utils/client/featureEnvUtils"
import { isI18nDebugMode } from "@/modules/featureEnv/utils/client/i18nDebugUtils"
import { getAssetsBaseUrl } from "@/modules/staticAssets/assetsManager/assetsManager"

import styles from "./FeatureEnvSwitcher.module.css"

const FeatureEnvSwitcher: FunctionComponent = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isFormHidden, setIsFormHidden] = useState(true)
  const [featureEnvFormData, setFeatureEnvFormData] = useState<FormDataType>({
    i18nDebugMode: false,
    monolithFeatureEnvDuration: FE_MONOLITH_DURATION_SHORT,
    monolithFeatureEnvIdentifier: "",
    nextFeatureEnvIdentifier: "",
    spaFeatureEnvIdentifier: "",
  })
  const [keysPressed, setKeysPressed] = useState("")

  useEffect(() => {
    // Get the feature env settings from the cookies, only on the client side
    setFeatureEnvFormData({
      i18nDebugMode: isI18nDebugMode(),
      monolithFeatureEnvDuration: getMonolithFeatureEnvInfos()?.duration || FE_MONOLITH_DURATION_SHORT,
      monolithFeatureEnvIdentifier: getMonolithFeatureEnvInfos()?.identifier || "",
      nextFeatureEnvIdentifier: getNextFeatureEnvIdentifier() || "",
      spaFeatureEnvIdentifier: getSpaFeatureEnvIdentifier() || "",
    })
    setIsCollapsed(window.localStorage.getItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY) === "true")
  }, [])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      let newKeysPressed = keysPressed + event.key

      // Check if the last key is 'Escape'
      if (event.key === "Escape") {
        setIsFormHidden(true)
        newKeysPressed = ""
      }

      // Check if the last two characters are 'fe' and if the focus is not on an input
      if (
        newKeysPressed.slice(-2) === "fe" &&
        !(event.target as HTMLElement).nodeName.match(/input|textarea|select/i)
      ) {
        setIsFormHidden(false)
        setIsCollapsed(false)
        window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, "false")
        newKeysPressed = ""
      }

      // Check if the last two characters are 'ef' and if the focus is not on an input
      if (
        newKeysPressed.slice(-2) === "ef" &&
        !(event.target as HTMLElement).nodeName.match(/input|textarea|select/i)
      ) {
        setIsFormHidden(true)
        setIsCollapsed(true)
        window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, "true")
        newKeysPressed = ""
      }

      setKeysPressed(newKeysPressed)
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [keysPressed, isCollapsed])

  const statusButtonContent = `
    ${featureEnvFormData.monolithFeatureEnvIdentifier || "staging"}
    ${featureEnvFormData.spaFeatureEnvIdentifier ? ` / ${featureEnvFormData.spaFeatureEnvIdentifier.slice(0, 8)}` : ""}
    ${featureEnvFormData.nextFeatureEnvIdentifier ? ` / ${featureEnvFormData.nextFeatureEnvIdentifier}` : ""}
  `

  const handleStatusButtonClick = (): void => {
    setIsCollapsed(prevState => !prevState)
    window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, String(!isCollapsed))
  }

  const handleReset = (): void => {
    deleteCookie({
      name: FE_COOKIE_NAME_RAILS,
      theDomain: FE_COOKIE_DOMAIN,
    })
    deleteCookie({
      name: FE_COOKIE_NAME_UI,
      theDomain: FE_COOKIE_DOMAIN,
    })
    deleteCookie({
      name: FE_COOKIE_NAME_NEXT,
      theDomain: FE_COOKIE_DOMAIN,
    })
    deleteCookie({
      name: I18N_DEBUG_COOKIE_NAME,
      theDomain: FE_COOKIE_DOMAIN,
    })
    window.location.reload()
  }

  const handleSubmit = ({
    monolithFeatureEnvIdentifier,
    monolithFeatureEnvDuration,
    spaFeatureEnvIdentifier,
    nextFeatureEnvIdentifier,
    i18nDebugMode,
  }: FormDataType): void => {
    // set or reset jobteaser feature env
    if (monolithFeatureEnvIdentifier) {
      setCookie({
        name: FE_COOKIE_NAME_RAILS,
        theDomain: FE_COOKIE_DOMAIN,
        value: `${monolithFeatureEnvDuration}-${monolithFeatureEnvIdentifier}-jobteaser`,
      })
    } else {
      deleteCookie({
        name: FE_COOKIE_NAME_RAILS,
        theDomain: FE_COOKIE_DOMAIN,
      })
    }

    // set or reset ui-jobteaser feature env
    if (spaFeatureEnvIdentifier) {
      setCookie({
        name: FE_COOKIE_NAME_UI,
        theDomain: FE_COOKIE_DOMAIN,
        value: `${getAssetsBaseUrl().replace(
          "/next-jobteaser",
          "/ui-jobteaser"
        )}/${spaFeatureEnvIdentifier}/manifest.json`,
      })
    } else {
      deleteCookie({
        name: FE_COOKIE_NAME_UI,
        theDomain: FE_COOKIE_DOMAIN,
      })
    }

    // set or reset next-jobteaser feature env
    if (nextFeatureEnvIdentifier) {
      setCookie({
        name: FE_COOKIE_NAME_NEXT,
        theDomain: FE_COOKIE_DOMAIN,
        value: nextFeatureEnvIdentifier,
      })
    } else {
      deleteCookie({
        name: FE_COOKIE_NAME_NEXT,
        theDomain: FE_COOKIE_DOMAIN,
      })
    }

    // set or reset i18n debug mode
    if (i18nDebugMode) {
      setCookie({
        name: I18N_DEBUG_COOKIE_NAME,
        theDomain: FE_COOKIE_DOMAIN,
        value: "1",
      })
    } else {
      deleteCookie({
        name: I18N_DEBUG_COOKIE_NAME,
        theDomain: FE_COOKIE_DOMAIN,
      })
    }

    window.location.reload()
  }

  return (
    <div
      className={classNames(styles.main, {
        [styles.main__collapsed]: isCollapsed,
      })}
    >
      {isFormHidden && (
        <button
          className={classNames(styles.button, styles.buttonStatus)}
          type="button"
          title="Click to reduce / expand this info"
          onClick={handleStatusButtonClick}
        >
          {statusButtonContent}
        </button>
      )}
      {!isFormHidden && (
        <FeatureEnvForm featureEnvFormData={featureEnvFormData} onSubmit={handleSubmit} onReset={handleReset} />
      )}
      {!isCollapsed && (
        <button
          className={classNames(styles.button, styles.buttonEdit)}
          type="button"
          title="Edit Feature env settings - PROTIP: use keyboard shortcut 'fe' / 'ef / 'Escape' to display / hide it"
          onClick={() => setIsFormHidden(prevState => !prevState)}
        />
      )}
    </div>
  )
}

// We need a default export for next/dynamic inside App Router
// eslint-disable-next-line import/no-default-export
export default FeatureEnvSwitcher
