import type { Metadata, TrackJSMod } from "./types"

import { createContext, useContext } from "react"

import { init } from "./init"

type TrackJsContextType = { trackJS: TrackJSMod | null; getMetadata?: () => Metadata }

const TrackJsContext = createContext<TrackJsContextType>({ trackJS: null })

const useTrackJs = (): TrackJSMod | null => {
  const { trackJS } = useContext(TrackJsContext)

  return trackJS
}

const useAsyncTrackJS = (): (() => Promise<TrackJSMod>) => {
  const { getMetadata } = useContext(TrackJsContext)

  return () => init(getMetadata)
}

export { TrackJsContext, useTrackJs, useAsyncTrackJS }
