import type { I18nLocale } from "@/modules/i18n/types"
import type { TrackingType } from "@/modules/tracking/types"
import type { UnleashContext } from "@/modules/unleash/types"

const CONFIG_TRACKING_JT = {
  batchConfigUrl: process.env.NEXT_PUBLIC_TRACKING_BATCH_CONFIG_URL,
  credentials: {
    cookieBanner: {
      id: process.env.NEXT_PUBLIC_TRACKING_AXEPTIO_CLIENT_ID,
      name: process.env.NEXT_PUBLIC_TRACKING_AXEPTIO_BANNER_NAME,
    },
    didomiEnabled: true,
    didomiNoticeId: process.env.NEXT_PUBLIC_TRACKING_DIDOMI_NOTICE_ID,
    rudderstack: {
      dataPlaneURL: process.env.NEXT_PUBLIC_TRACKING_RUDDERSTACK_PLANE_URL,
      jsKey: process.env.NEXT_PUBLIC_TRACKING_RUDDERSTACK_JS_KEY,
    },
  },

  debug: process.env.NODE_ENV === "development",
  // If `trackingEnabled` if false, no events are sent to Rudderstack
  trackingEnabled: process.env.NEXT_PUBLIC_TRACKING_ENABLED,
}

export function getTrackingConfig(locale: I18nLocale, unleashContext: UnleashContext): unknown {
  const cookieBannerNameWithLocale = `${CONFIG_TRACKING_JT.credentials.cookieBanner.name}_${locale}`

  return {
    ...CONFIG_TRACKING_JT,
    context: {
      is_not_a_bot: unleashContext.is_not_a_bot === "true",
      user_country: unleashContext.user_country,
    },
    credentials: {
      ...CONFIG_TRACKING_JT.credentials,
      cookieBanner: {
        ...CONFIG_TRACKING_JT.credentials.cookieBanner,
        name: cookieBannerNameWithLocale,
      },
    },
    locale,
    startRudderstackOnInit: false,
  }
}

export const initTracking = async (
  locale: I18nLocale,
  unleashContext: UnleashContext,
  callback: (arg: TrackingType) => void
): Promise<void> => {
  /*
   * Using dynamic import ensures that code that relies on the window object
   * is only loaded client-side, which prevents the “ReferenceError: window is not defined” error
   * and improves website performance.
   */
  const { TrackingJT } = await import("@jobteaser/js-tracking")
  const trackingJT: TrackingType = TrackingJT(getTrackingConfig(locale, unleashContext))

  if (callback) {
    callback(trackingJT)
  }
}
