import type { FormDataType, MonolithFeatureEnvDuration } from "@/modules/featureEnv/types"
import type { FormEvent, FunctionComponent } from "react"

import classNames from "classnames"

import { FE_MONOLITH_DURATION_LONG, FE_MONOLITH_DURATION_SHORT } from "@/modules/featureEnv/constants"

import styles from "./FeatureEnvForm.module.css"

type FeatureEnvFormProps = {
  featureEnvFormData: FormDataType
  onSubmit: (params: FormDataType) => void
  onReset: () => void
}

export const FeatureEnvForm: FunctionComponent<FeatureEnvFormProps> = ({ featureEnvFormData, onSubmit, onReset }) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)

    const monolithFeatureEnvIdentifier = (formData.get("monolith-fe-identifier") || "") as string
    const monolithFeatureEnvDuration = (formData.get("monolith-fe-duration") ||
      FE_MONOLITH_DURATION_SHORT) as MonolithFeatureEnvDuration
    const spaFeatureEnvIdentifier = (formData.get("spa-fe-identifier") || "") as string
    const nextFeatureEnvIdentifier = (formData.get("next-fe-identifier") || "") as string
    const i18nDebugMode = formData.get("i18n-debug") === "on"

    onSubmit({
      i18nDebugMode,
      monolithFeatureEnvDuration,
      monolithFeatureEnvIdentifier,
      nextFeatureEnvIdentifier,
      spaFeatureEnvIdentifier,
    })
  }
  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <h3>Feature Env settings</h3>
      <label htmlFor="monolith-fe-identifier">
        <span>jobteaser</span>
        <input
          type="text"
          placeholder="staging"
          name="monolith-fe-identifier"
          id="monolith-fe-identifier"
          defaultValue={featureEnvFormData.monolithFeatureEnvIdentifier}
        />
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          defaultValue={featureEnvFormData.monolithFeatureEnvDuration}
          name="monolith-fe-duration"
          id="monolith-fe-duration"
        >
          <option value={FE_MONOLITH_DURATION_SHORT}>Short</option>
          <option value={FE_MONOLITH_DURATION_LONG}>Long</option>
        </select>
      </label>
      <label htmlFor="spa-fe-identifier">
        <span>ui-jobteaser</span>
        <input
          type="text"
          placeholder="prod"
          name="spa-fe-identifier"
          id="spa-fe-identifier"
          defaultValue={featureEnvFormData.spaFeatureEnvIdentifier}
        />
      </label>
      <label htmlFor="next-fe-identifier">
        <span>next-jobteaser</span>
        <input
          type="text"
          placeholder="staging"
          name="next-fe-identifier"
          id="next-fe-identifier"
          defaultValue={featureEnvFormData.nextFeatureEnvIdentifier}
        />
      </label>
      <label htmlFor="i18n-debug">
        <span>i18n debug</span>
        <input type="checkbox" name="i18n-debug" id="i18n-debug" defaultChecked={featureEnvFormData.i18nDebugMode} />
      </label>
      <button className={classNames(styles.formButton, styles.formButtonSave)} type="submit">
        Save
      </button>
      <button className={classNames(styles.formButton, styles.formButtonDelete)} type="button" onClick={onReset}>
        Reset all & go back to staging
      </button>
    </form>
  )
}
