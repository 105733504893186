import type { Metadata, TrackJSMod } from "./types"

import { getCookie } from "@/modules/client/cookies/getCookies"
import { getCurrentEnv, isEnvLocal } from "@/modules/env/utils"

import { getTrackJSConfiguration } from "./config"

export async function init(metadata?: Metadata | (() => Metadata)): Promise<TrackJSMod> {
  return import("trackjs").then(mod => {
    const { TrackJS } = mod

    const isAcceptedEnvToTrackJS = !isEnvLocal()

    if (!TrackJS.isInstalled() && isAcceptedEnvToTrackJS) {
      const trackJsOptionsConfig =
        typeof window !== "undefined"
          ? {
              sessionId: getCookie("session_id"),
              userId: getCookie("visitor_id"),
            }
          : {}

      TrackJS.install({
        ...getTrackJSConfiguration(trackJsOptionsConfig),
      })

      if (getCurrentEnv() === "feature" && typeof window !== "undefined") {
        const featureEnvMatch = document.cookie.match(/\bFEATURE_NEXT=(\w+)/)
        if (featureEnvMatch && featureEnvMatch[1]) {
          TrackJS.addMetadata("feature-env", featureEnvMatch[1])
        }
      }
    }

    // Update "feature" every time `init` is called, in case we navigated to
    // another page & metadata changed.
    if (TrackJS.isInstalled() && isAcceptedEnvToTrackJS) {
      const data = typeof metadata === "function" ? metadata() : metadata
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          TrackJS.addMetadata(key, value)
        })
      }
    }

    return TrackJS
  })
}
