"use client"

import type { Metadata, TrackJsFeatureMetadataRecord, TrackJSMod } from "./types"
import type { EnabledFlag } from "@/modules/unleash/types"
import type { PropsWithChildren, ReactNode } from "react"

import { Component, lazy, Suspense } from "react"

import { getClientPageType } from "@/modules/routing/routingManager/clientRoutingManager"
import { computeActiveFlagsToTrackJsMetadata } from "@/modules/unleash/computeActiveFlagsToTrackJsMetadata"

import { init } from "./init"
import { TrackJsContext } from "./useTrackJs"

const DynamicErrorComponent = lazy(() => import("./Error").then(mod => ({ default: mod.ErrorComponent })))

type TrackJsProviderProps = PropsWithChildren<{
  activeUnleashFlags?: EnabledFlag[]
  trackJsMetadata?: TrackJsFeatureMetadataRecord
  renderErrorFallback?: boolean
}>
type TrackJsProviderState = { getMetadata: () => Metadata; hasError: boolean; trackJS: TrackJSMod | null }

export class TrackJsProvider extends Component<TrackJsProviderProps, TrackJsProviderState> {
  queue: Parameters<OnErrorEventHandlerNonNull>[] = []

  getMetadata: () => Metadata

  constructor(props: TrackJsProviderProps) {
    super(props)

    this.getMetadata = () => {
      return {
        feature: getClientPageType() || window.location.pathname,
        ...computeActiveFlagsToTrackJsMetadata(props?.activeUnleashFlags || []),
        ...props?.trackJsMetadata,
      }
    }

    // getMetadata is passed with this.state in the render
    // eslint-disable-next-line react/no-unused-state
    this.state = { getMetadata: this.getMetadata, hasError: false, trackJS: null }

    this.getMetadata = this.getMetadata.bind(this)
    this.onWindowError = this.onWindowError.bind(this)
  }

  static getDerivedStateFromError(): Partial<TrackJsProviderState> {
    return { hasError: true }
  }

  componentDidMount(): void {
    if (typeof window !== "undefined") {
      window.addEventListener("error", this.onWindowError)
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener("error", this.onWindowError)
  }

  componentDidCatch(error: Error): void {
    this.init().then(TrackJS => {
      TrackJS.track(error)
    })
  }

  onWindowError(...args: Parameters<OnErrorEventHandlerNonNull>): void {
    this.queue.push(args)

    this.init().then(TrackJS => {
      let queueElement

      // eslint-disable-next-line no-cond-assign
      while ((queueElement = this.queue.shift())) {
        const [event, , lineno, colno, error] = queueElement

        const queueElementTrackJSCompatible = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          column: (error as any)?.column || (event as ErrorEvent)?.colno || parseInt(`${colno}`, 10) || null,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          line: (error as any)?.line || (event as ErrorEvent)?.lineno || parseInt(`${lineno}`, 10) || null,

          message: error?.message || (event as ErrorEvent)?.message || event,

          name: error?.name || (event as ErrorEvent)?.type || "Error",
          stack: error?.stack || (event as ErrorEvent)?.filename || null,
        }

        TrackJS.track(queueElementTrackJSCompatible)
      }
    })
  }

  async init(): Promise<TrackJSMod> {
    const { trackJS } = this.state

    if (trackJS) {
      return trackJS
    }

    return init(this.getMetadata).then(TrackJS => {
      this.setState({
        hasError: true,
        trackJS: TrackJS,
      })

      return TrackJS
    })
  }

  render(): ReactNode {
    const { children, renderErrorFallback } = this.props
    const { hasError } = this.state

    if (hasError && renderErrorFallback !== false) {
      return (
        <Suspense>
          <DynamicErrorComponent />
        </Suspense>
      )
    }

    return <TrackJsContext.Provider value={this.state}>{children}</TrackJsContext.Provider>
  }
}
