import type { CookieType } from "@/modules/client/cookies/types"

import { setCookie } from "@/modules/client/cookies/setCookie"

type DeleteCookieParams = Omit<CookieType, "value" | "secure" | "expires">

export const deleteCookie = ({ name, path = "/", theDomain }: DeleteCookieParams): void => {
  if (typeof window === "undefined") {
    return
  }

  if (!name) {
    return
  }

  setCookie({
    expires: new Date(0),
    name,
    path,
    theDomain,
    value: "",
  })
}
